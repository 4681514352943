<template>
  <div class="float-suggestions-search" v-if="show">
    <div class="d-flex justify-content-between align-items-center p-2" v-if="query.length > 0">
      <span class="d-block">{{$t('search.searchingBy')}}: {{query}}</span>
      <b-spinner variant="secondary" v-if="loader"> </b-spinner>
    </div>
    <div>
      <b-media vertical-align="center" class="d-flex align-items-center media-user-search" v-for="user in data" :key="user.user_id" @click="onSelected(user)">
        <template #aside>
          <div class="position-relative">
            <b-avatar
              size="md"
              variant="light-primary"
              class="main-avatar"
              :src="user.avatar_url"
            />
              <b-avatar size="20px" class="mr-1 container-avatar-table-icon" variant="light">
                <b-img :src="getIconWhite(user.type)" :class="getIconColor(user.type, false)" fluid/>
              </b-avatar>
          </div>
        </template>
        <div v-if="user.type !== 'empty'">
          <span class="d-block f-16">{{user.username }} <image-verified class="iconCheck"/></span>
          <div class="d-block avenir-light d-flex align-items-center">{{ user.title }}  <span class="span-dot"> </span> {{ getFormat(user.subscribers_count) }} Seguidores</div>
        </div>
        <div v-else>
          <div class="d-block avenir-light d-flex align-items-center">{{ user.title }}</div>
        </div>
      </b-media>
    </div>
  </div>
</template>

<script>
import {
  BAvatar,
  BMedia,
  BSpinner,
  BImg,
} from 'bootstrap-vue';
import { capitalize, getFormat } from '@/libs/utils/formats';
import { getUserData, hasPermission } from '@/libs/utils/user'
import { getIconWhite, getIconColor } from '@/libs/utils/icons';

export default {
  name: 'suggestionsContainerAutoSearch',
  components: {
    BAvatar,
    BMedia,
    BSpinner,
    BImg,
    imageVerified: () => import('@/views/components/image/ImageVerified.vue')
  },
  props: {
    show_suggestions: {
      type: Boolean,
      required: true,
      default: false
    },
    data: {
      type: Array,
    },
    query: {
      type: String,
    },
    loader: {
      type: Boolean,
      default: false,
    },
    networkSearch: {
      type: String,
    }
  },
  data() {
    return {
      getIconWhite,
      getIconColor,
      capitalize,
      getFormat,
      show: this.show_suggestions,
      user_data: getUserData(),
    }
  },
  methods: {
    onSelected(item) {
      if (item.type === 'empty') {
        this.$router.push(`/search`)
      } else if (hasPermission('view_profile')) {
        this.$emit('open_side_summary', item);
        // const username = this.networkSearch === 'youtube' ? item.channel_id : item.username
        // this.$router.push(`/perfil/${this.networkSearch}/${username}`)
      } else {
        this.$emit('open_modal_membership');
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.float-suggestions-search {
  width: 100%;
  height: auto;
  background-color: white;
  border-radius: 1em;
  position: absolute;
  top: 5em;
  z-index: 100;
  box-shadow: 0 2px 15px #0003;
  overflow: hidden;
  .media-user-search {
    padding: 10px 2em 10px 2em;
    border-top: 1px solid #e2e8f0;
    // border-radius: 1em;

    &:hover {
      background-color: #F7FAFC;
      cursor: pointer;
      transition: all 100ms;
    }
    .main-avatar {
      height: 48px;
      width: 48px;
    }
    .container-avatar-table-icon {
      position: absolute; 
      bottom: -5px; 
      right: -15px;
    }
    .f-16 {
      font-size: 16px;
    }
    .span-dot {
      width: 7px;
      height: 7px;
      background-color: #718096;
      display: block;
      border-radius: 100%;
      margin: 0 0.5em 0 0.5em;
      // margin: 0 10px;
      // font-size: 6px;
    }
    .avenir-light {
      font-family: 'avenir-medium';
      color: #718096;
    }
  }
}
</style>